import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AnalogCountdown = ({ targetDate, isNegative: parentIsNegative }) => {
  const [remainingTime, setRemainingTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    isNegative: false
  });

  useEffect(() => {
    const calculateRemainingTime = () => {
      const now = new Date();
      const difference = targetDate - now;
       const isNegative = parentIsNegative || difference < 0;
      const absDifference = Math.abs(difference);;

      const days = Math.floor(absDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((absDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((absDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((absDifference % (1000 * 60)) / 1000);

      setRemainingTime({ days, hours, minutes, seconds, isNegative });
    };

    const interval = setInterval(calculateRemainingTime, 1000);
    calculateRemainingTime();

    return () => clearInterval(interval);
  }, [targetDate, parentIsNegative]);

  const { days, hours, minutes, seconds, isNegative } = remainingTime;

  return (
    <Container>
      <ClockContainer>
        <ClockFace>
          {/* Clock Center Dot */}
          <CenterDot />
          
          {/* Clock Markers */}
          {[...Array(12)].map((_, i) => (
            <ClockMarker key={i} rotation={i * 30} />
          ))}
          
          <ClockHand type="days" rotation={days} isNegative={isNegative} />
          <ClockHand type="hours" rotation={hours} isNegative={isNegative} />
          <ClockHand type="minutes" rotation={minutes} isNegative={isNegative} />
          <ClockHand type="seconds" rotation={seconds} isNegative={isNegative} />
        </ClockFace>
      </ClockContainer>
      
     <DigitalDisplay>
      <TimeUnit>
        <TimeValue isNegative={isNegative}>{days}</TimeValue>
        <TimeLabel isNegative={isNegative}>days</TimeLabel>
      </TimeUnit>
      <TimeUnit>
        <TimeValue isNegative={isNegative}>{hours}</TimeValue>
        <TimeLabel isNegative={isNegative}>hours</TimeLabel>
      </TimeUnit>
      <TimeUnit>
        <TimeValue isNegative={isNegative}>{minutes}</TimeValue>
        <TimeLabel isNegative={isNegative}>minutes</TimeLabel>
      </TimeUnit>
      <TimeUnit>
        <TimeValue isNegative={isNegative}>{seconds}</TimeValue>
        <TimeLabel isNegative={isNegative}>secs</TimeLabel>
      </TimeUnit>
    </DigitalDisplay>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;

const ClockContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }
`;

const ClockFace = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenterDot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  z-index: 2;
`;

const ClockMarker = styled.div`
  position: absolute;
  width: 2px;
  height: 15px;
  display: none;
  background: rgba(255, 255, 255, 0.4);
  transform-origin: bottom center;
  transform: rotate(${props => props.rotation}deg) translateY(-140px);
`;

const ClockHand = styled(motion.div)`
  position: absolute;
  bottom: 50%;
  width: ${({ type }) => {
    switch (type) {
      case 'seconds': return '1px';
      case 'minutes': return '3px';
      case 'hours': return '4px';
      case 'days': return '5px';
      default: return '2px';
    }
  }};
  height: ${({ type }) => {
    switch (type) {
      case 'days': return '35%';
      case 'hours': return '45%';
      case 'minutes': return '55%';
      case 'seconds': return '65%';
      default: return '0';
    }
  }};
  background: ${({ type, isNegative }) =>
    isNegative 
      ? '#000000' 
      : type === 'seconds' 
        ? 'rgba(255, 255, 255, 0.8)' 
        : 'rgba(255, 255, 255, 0.6)'};
  transform-origin: bottom center;
  transform: rotate(${({ rotation }) => rotation * 6}deg);
  border-radius: 50% 50% 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

const DigitalDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  padding: 0 20px;
`;

const TimeUnit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;


const TimeValue = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${({ isNegative }) => 
    isNegative ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
`;

const TimeLabel = styled.div`
  font-size: 0.9rem;
  color: ${({ isNegative }) => 
    isNegative ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)'};
  text-transform: uppercase;
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
`;

export default AnalogCountdown;

import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence, useScroll, useTransform } from 'framer-motion';
import Countdown from 'react-countdown';
import moment from 'moment';
import AnalogCountdown from './AnalogCountdown';

const AppContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  overflow: hidden;
  color: white;
  font-family: 'Inter', sans-serif;
  background: #000;
    overflow-y: auto; // Allow vertical scrolling when needed

`;


const EnhancedVideo = styled(motion.video)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.7) blur(12px) contrast(1.1)
    ${props => props.isNegative ? 'invert(1)' : 'invert(0)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
`;

const GridCanvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
`;

const StyledContent = styled(motion.div)`
  position: relative;
  z-index: 2;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around; // Changed from center
  padding: 2rem;
  gap: 3rem; // Increased gap
   margin-top: 80px; // Add this to create space below navbar

  @media (max-width: 768px) {
    padding: 1rem;
    gap: 2rem;
  }

    .hero-section {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .features-section {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 4rem 0;
    
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
       margin-top: 60px; // Add this to create space below navbar

   }
  }
`;


const LogoText = styled.h1`
  font-family: 'Prata', serif;
  font-size: clamp(2.5rem, 8vw, 4.5rem); // Responsive font size
  font-weight: 300;
  letter-spacing: 1.2rem;
  margin: 0;
  cursor: pointer;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  text-shadow: ${props => props.isNegative ? 
    '0 0 30px rgba(0,0,0,0.3)' : 
    '0 0 30px rgba(255,255,255,0.3)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  
  @media (max-width: 768px) {
    letter-spacing: 0.8rem;
  }
  
  @media (max-width: 480px) {
    letter-spacing: 0.6rem;
  }
`;

const MainSlogan = styled(motion.div)`
  text-align: center;
  
  .word {
    font-family: 'Prata', serif;
    font-size: clamp(3rem, 10vw, 8rem);
    font-weight: 300;
    line-height: 1;
    display: block;
    color: ${props => props.isNegative ? '#000' : '#fff'};
    text-shadow: ${props => props.isNegative ? 
      '0 0 40px rgba(0,0,0,0.3)' : 
      '0 0 40px rgba(255,255,255,0.3)'};
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    
    &.emphasis {
      font-size: clamp(4rem, 12vw, 10rem);
      letter-spacing: 0.2em;
      margin: 0.2em 0;
    }
  }
`;

const MainSloganContainer = styled(motion.div)`
  text-align: center;
  padding: 0.8rem;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 4rem;
  position: relative;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  
  @media (max-width: 768px) {
    padding: 0.5rem;
    max-width: 100%;
  }
`;

const SloganWord = styled(motion.div)`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0.2rem 0;
  width: 100%;

  .icon-left, .icon-right {
    position: absolute;
    font-size: clamp(0.8rem, 1.5vw, 1.2rem);
    opacity: 0.6;
    color: ${props => props.isNegative ? '#000' : '#fff'};
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const WordWhere = styled.span`
  font-family: 'Playfair Display', serif;
  font-size: clamp(1.5rem, 4vw, 2.5rem); // Reduced size
  font-style: italic;
  font-weight: 400;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  text-shadow: ${props => props.isNegative ? 
    '0 0 20px rgba(0,0,0,0.3)' : 
    '0 0 20px rgba(255,255,255,0.3)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  
  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const WordFashion = styled.span`
  font-family: 'Cinzel', serif;
  font-size: clamp(2.5rem, 8vw, 5rem); // Reduced size
  font-weight: 700;
  letter-spacing: 0.1em; // Reduced from 0.15em
  background: ${props => props.isNegative ? 
    'linear-gradient(45deg, #000, #333)' : 
    'linear-gradient(45deg, #fff, #ccc)'};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  margin: 0.1em 0; // Reduced from 0.2em
  position: relative;
  
  @media (max-width: 480px) {
    font-size: 2rem;
    letter-spacing: 0.05em;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: -5px; // Reduced from -10px
    left: 50%;
    transform: translateX(-50%);
    width: 40%; // Reduced from 60%
    height: 1px;
    background: ${props => props.isNegative ? 
      'linear-gradient(90deg, transparent, rgba(0,0,0,0.3), transparent)' : 
      'linear-gradient(90deg, transparent, rgba(255,255,255,0.3), transparent)'};
  }
`;

const WordFlourishes = styled.span`
  font-family: 'Cormorant Garamond', serif;
  font-size: clamp(1.5rem, 4vw, 2.5rem); // Reduced size
  font-weight: 300;
  letter-spacing: 0.05em; // Reduced from 0.1em
  color: ${props => props.isNegative ? '#000' : '#fff'};
  text-shadow: ${props => props.isNegative ? 
    '0 0 20px rgba(0,0,0,0.3)' : 
    '0 0 20px rgba(255,255,255,0.3)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  
  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

// Smaller decorative elements
const DecorativeElement = styled(motion.div)`
  position: absolute;
  width: 20px; // Reduced from 40px
  height: 20px; // Reduced from 40px
  border: 1px solid ${props => props.isNegative ? '#000' : '#fff'}; // Reduced from 2px
  opacity: 0.3;
  
  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }
  
  @media (max-width: 480px) {
    width: 10px;
    height: 10px;
  }
  
  &.top-left {
    top: 0;
    left: 0;
    border-right: none;
    border-bottom: none;
  }
  
  &.bottom-right {
    bottom: 0;
    right: 0;
    border-left: none;
    border-top: none;
  }
`;


const Slogan = styled(motion.div)`
  font-family: 'Inter', sans-serif;
  font-size: clamp(0.8rem, 2vw, 1.2rem);
  font-weight: 300;
  letter-spacing: 0.3rem;
  margin-top: -1rem;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  text-shadow: ${props => props.isNegative ? 
    '0 0 10px rgba(0,0,0,0.3)' : 
    '0 0 10px rgba(255,255,255,0.3)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  
  span {
    opacity: 0.8;
  }
`;

const MonoDisplay = styled.div`
  position: fixed;
  top: 1.5rem;
  left: 1.5rem;
  font-family: 'Fira', sans-serif;
  font-size: 0.85rem;
  z-index: 3;
  opacity: 0.8;
  background: rgba(0, 0, 0, 0.2);
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  
  pre {
    margin: 0;
    color: #a0f0c0;
  }
`;

const CountdownWrapper = styled(motion.div)`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  
  
  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const FloatingText = styled(motion.div)`
  font-size: ${props => props.size || '1rem'};
  font-weight: ${props => props.weight || '300'};
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  text-shadow: ${props => props.isNegative ? 
    '0 0 10px rgba(0,0,0,0.3)' : 
    '0 0 10px rgba(255,255,255,0.3)'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
`;

const GlassPanel = styled(motion.div)`
  background: ${props => props.isNegative ? 
    'rgba(255,255,255,0.15)' : 
    'rgba(255,255,255,0.1)'};
  backdrop-filter: blur(12px);
  padding: 4rem 6rem;
  border-radius: 24px;
  border: 1px solid rgba(255,255,255,0.2);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  align-items: center;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0,0,0,0.1);

  @media (max-width: 768px) {
    padding: 2rem;
    gap: 1.5rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 1.5rem;
  }
`;

const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);

  .number {
    font-size: 3.5rem;
    font-weight: 200;
    text-shadow: ${props => props.isNegative ? 
      '0 0 20px rgba(0,0,0,0.3)' : 
      '0 0 20px rgba(255,255,255,0.3)'};
    
    @media (max-width: 768px) {
      font-size: 2.5rem;
    }
  }

  .label {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    opacity: 0.8;
    
    @media (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
`;


// Add these new styled components
const Navbar = styled(motion.nav)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 3rem;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
  }
`;

const NavLogo = styled(motion.div)`
  font-family: 'Prata', serif;
  font-size: 1.8rem;
  font-weight: 300;
  letter-spacing: 0.8rem;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  cursor: pointer;
  
  @media (max-width: 768px) {
    letter-spacing: 0.4rem;
    font-size: 1.4rem;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1.5rem;
  
  a {
    color: ${props => props.isNegative ? '#000' : '#fff'};
    opacity: 0.7;
    transition: opacity 0.3s ease;
    font-size: 1.2rem;
    
    &:hover {
      opacity: 1;
    }
  }
`;

// const ModernCountdown = styled(motion.div)`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 2rem;
// `;

const CountdownTitle = styled(motion.h2)`
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  font-weight: 300;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  opacity: 0.9;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  margin: 0;
`;


const ModernCountdown = styled(motion.div)`
  position: relative;
  background: ${props => props.isNegative ? 
    'rgba(0, 0, 0, 0.05)' : 
    'rgba(255, 255, 255, 0.05)'};
  backdrop-filter: blur(20px);
  border-radius: 20px;
  padding: 2rem;
  border: 1px solid ${props => props.isNegative ? 
    'rgba(0, 0, 0, 0.1)' : 
    'rgba(255, 255, 255, 0.1)'};
  
  @media (max-width: 768px) {
    padding: 1.5rem;
    border-radius: 15px;
  }
`;

const TimeUnit = styled.div`
  .number {
    font-size: clamp(2rem, 6vw, 4rem);
    color: ${props => props.isNegative ? '#000' : '#fff'};
    text-shadow: ${props => props.isNegative ? 
      '0 0 20px rgba(0,0,0,0.2)' : 
      '0 0 20px rgba(255,255,255,0.2)'};
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    
    @media (max-width: 768px) {
      font-size: clamp(1.8rem, 5vw, 2.5rem);
    }
  }
  
  .label {
    font-size: clamp(0.7rem, 2vw, 0.9rem);
    letter-spacing: 2px;
    opacity: 0.8;
    color: ${props => props.isNegative ? '#000' : '#fff'};
    transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
    
    @media (max-width: 768px) {
      letter-spacing: 1px;
    }
  }
`;

const CountdownDisplay = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  color: ${props => props.isNegative ? '#000' : '#fff'};
  transition: all 0.8s cubic-bezier(0.16, 1, 0.3, 1);
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    
    // Add a decorative line between items
    ${TimeUnit} + ${TimeUnit} {
      position: relative;
      padding-top: 1.5rem;
      
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 20%;
        right: 20%;
        height: 1px;
        background: ${props => props.isNegative ? 
          'rgba(0,0,0,0.2)' : 
          'rgba(255,255,255,0.2)'};
      }
    }
  }
`;




function App() {
  const isMobile = () => window.innerWidth <= 768;
  const [isNegative, setIsNegative] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
  const canvasRef = React.useRef(null);
  const mousePos = React.useRef({ x: 0, y: 0 });
  const targetPos = React.useRef({ x: 0, y: 0 });
  const gridSize = 20;
  const blurRadius = 10;
  const targetDate = moment('2024-12-16').toDate();
 const handleLogoInteraction = (e) => {
    if (isMobile()) {
      // Toggle on tap for mobile
      setIsNegative(prev => !prev);
    } else {
      // Keep hover behavior for desktop
      setIsNegative(e.type === 'mouseenter');
    }
  };
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 10
      }
    }
  };
  const drawGrid = useCallback((ctx, width, height) => {
    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
    ctx.lineWidth = 1;

    const mouseX = mousePos.current.x;
    const mouseY = mousePos.current.y;

    // Smooth movement
    targetPos.current.x += (mouseX - targetPos.current.x) * 0.1;
    targetPos.current.y += (mouseY - targetPos.current.y) * 0.1;

    for (let x = 0; x <= width; x += gridSize) {
      for (let y = 0; y <= height; y += gridSize) {
        const distX = x - targetPos.current.x;
        const distY = y - targetPos.current.y;
        const distance = Math.sqrt(distX * distX + distY * distY);
        const intensity = Math.max(0, 1 - distance / 300);
        
        const offsetX = (distX / distance || 0) * intensity * 15;
        const offsetY = (distY / distance || 0) * intensity * 15;

        ctx.beginPath();
        ctx.arc(
          x + offsetX,
          y + offsetY,
          1 + intensity * 2,
          0,
          Math.PI * 2
        );
        ctx.fillStyle = `rgba(255, 255, 255, ${0.1 + intensity * 0.3})`;
        ctx.fill();
      }
    }
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    const handleMouseMove = (e) => {
      mousePos.current = {
        x: e.clientX,
        y: e.clientY
      };
    };

    const animate = () => {
      drawGrid(ctx, canvas.width, canvas.height);
      animationFrameId = requestAnimationFrame(animate);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('mousemove', handleMouseMove);
    animate();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      cancelAnimationFrame(animationFrameId);
    };
  }, [drawGrid]);

const countdownRenderer = ({ days, hours, minutes, seconds }) => {
  return (
    <ModernCountdown isNegative={isNegative}>
      <CountdownTitle isNegative={isNegative}>Coming Soon</CountdownTitle>
      <CountdownDisplay isNegative={isNegative}>
        <TimeUnit isNegative={isNegative}>
          <div className="number">{String(days).padStart(2, '0')}</div>
          <div className="label">Days</div>
        </TimeUnit>
        <TimeUnit isNegative={isNegative}>
          <div className="number">{String(hours).padStart(2, '0')}</div>
          <div className="label">Hours</div>
        </TimeUnit>
        <TimeUnit isNegative={isNegative}>
          <div className="number">{String(minutes).padStart(2, '0')}</div>
          <div className="label">Minutes</div>
        </TimeUnit>
        <TimeUnit isNegative={isNegative}>
          <div className="number">{String(seconds).padStart(2, '0')}</div>
          <div className="label">Seconds</div>
        </TimeUnit>
      </CountdownDisplay>
    </ModernCountdown>
  );
};

 return (
  <AppContainer>
    <Navbar>
        <NavLogo
          isNegative={isNegative}
          onMouseEnter={(e) => handleLogoInteraction(e)}
          onMouseLeave={(e) => handleLogoInteraction(e)}
          onClick={(e) => handleLogoInteraction(e)}
        >
          SAVITAA
        </NavLogo>
      <SocialIcons isNegative={isNegative}>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="#" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook"></i>
        </a>
      </SocialIcons>
    </Navbar>
    <EnhancedVideo
  autoPlay
  loop
  muted
  playsInline
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  transition={{ duration: 1 }}
  isNegative={isNegative}
>
  <source src="/assets/video.mp4" type="video/mp4" />
</EnhancedVideo>
    
    <GridCanvas ref={canvasRef} />

    <StyledContent
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
     <motion.div
  dragConstraints={{
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  }}
  whileHover={{ scale: 1.05 }}
  variants={itemVariants}
>
<MainSloganContainer
        isNegative={isNegative}
        // onMouseEnter={(e) => handleLogoInteraction(e)}
        // onMouseLeave={(e) => handleLogoInteraction(e)}
        onClick={(e) => handleLogoInteraction(e)}
      >
  <DecorativeElement 
    className="top-left" 
    isNegative={isNegative}
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 0.3, scale: 1 }}
    transition={{ delay: 0.3, duration: 0.4 }}
  />
  
  <SloganWord 
    isNegative={isNegative}
    variants={{
      hidden: { opacity: 0, x: -20 },
      visible: { opacity: 1, x: 0 }
    }}
  >
    <WordWhere isNegative={isNegative}>Where</WordWhere>
  </SloganWord>
  
  <SloganWord 
    isNegative={isNegative}
    variants={{
      hidden: { opacity: 0, y: 20 },
      visible: { opacity: 1, y: 0 }
    }}
  >
    <WordFashion isNegative={isNegative}>Fashion</WordFashion>
  </SloganWord>
  
  <SloganWord 
    isNegative={isNegative}
    variants={{
      hidden: { opacity: 0, x: 20 },
      visible: { opacity: 1, x: 0 }
    }}
  >
    <WordFlourishes isNegative={isNegative}>Flourishes</WordFlourishes>
  </SloganWord>
  
  <DecorativeElement 
    className="bottom-right" 
    isNegative={isNegative}
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 0.3, scale: 1 }}
    transition={{ delay: 0.3, duration: 0.4 }}
  />
</MainSloganContainer>

</motion.div>
     <CountdownWrapper variants={itemVariants}>
  <AnalogCountdown targetDate={targetDate} isNegative={isNegative} />
</CountdownWrapper>

      <FloatingText variants={itemVariants} isNegative={isNegative}>
  Countdown to launch
</FloatingText>

      </StyledContent>
    </AppContainer>
  );
}

export default App;
